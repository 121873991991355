/*************
	HEADER NAV
*************/

@media (max-width: 1440px) {
	#content-wrapper {
		padding-left: 30px;
		padding-right: 30px;
	}
}

// A MODIFIER QUAND BONS INTITULES
@media (max-width: 1280px) {

	#header, #footer{
		padding-left: 30px;
		padding-right: 30px;
	}
	iframe {
    	max-width: 100% !important;
    	height: auto;
	}
}
// NAV MOBILE


@media (max-width: 1050px) {
	#header #portal-globalnav-wrapper {
     	width: 300px;
     	margin-top: 0px;
     	.plone-navbar-header{float: none;}
	}
	#portal-globalnav-collapse{
		padding: 0;
		clear: both;
		margin-top: 30px;
	}
	.container{
		padding: 0;
	}
	
	#header, #footer{
		padding-left: 30px;
		padding-right: 30px;
	}
	#header{
		position: relative;
	}
	
	#portal-tophead{
		float: right;
		margin-top: 20px;		
	}
	#portal-header{
		clear: none;
		margin-left: 70px !important;
		position: relative;
		z-index: 130;
	}

	#search-bar{
  		right: 120px;
	}
	#search-bar.toggled {
	    width: 155%;
	}
	#myCarousel{
		margin-top: 20px;
	}

	#header #portal-globalnav-wrapper{
		position: absolute;
		left: 0;
		top: 20px;
		z-index: 110;
		.container{
			padding-left: 0;
		}
		.plone-navbar-toggle {
		    z-index: 1;
		    position: relative;
		    float: left;
		    margin: 0px 0 0 30px;
		    padding: 0;
		    background-color: @red;
		    background-image: none;
		    border: 0;
		    border-radius: 0;
		    text-indent: -9000px;
		    height: 50px;
		    width: 50px;
		    padding: 10px;
		    .sr-only{display: none;}
		    .icon-bar{
		    	background-color: #FFF;
		    	height: 4px;
				margin: 3px 0 5px 0;
		    	width: 100%;
		    	display: block;
		    }
		}
	}
	
	#header #portal-globalnav-wrapper #portal-globalnav ul {
        position: static;
        width: 100%;
        min-width: 100%;
        a{
        	background-color: #e14a57;
	        padding: 0.5em 1.5em 0.5em 3em !important;
	    }
	    ul a{
	    	padding: 0.5em 1.5em 0.5em 4em !important;
	    }
	}
	#header #portal-globalnav-wrapper .opener {
      display: none;

      &+label:after {
        transform: rotate(0deg);
        transition: ease-in 0.35s;
      }

      
        &:checked+label:after {
          transform: rotate(-180deg);
          transition: ease-in 0.35s;
        }
      

      &~ul {
        transition: none;
        display:none;
      }

      &:checked~ul {
        height: auto;
        transition: none;
        visibility: visible;
        opacity: 1;
        display: block;
        
      }

      
    }

	// MENUS

	// A SUPPRIMER ENSUITE
	#portal-globalnav .has_subtree:hover > ul, #portal-globalnav .has_subtree .has_subtree:hover > ul{
		display: block;
        position: static;
        width: 100%;
        min-width: 100%;
        height: auto;
        a{
        	background-color: #e14a57;
	        padding: 0.5em 1.5em 0.5em 3em !important;
	    }
	}
	// 
	#header #portal-globalnav{
		background: @red;
		float: none;
		margin-top: 00px;
		display: flex;
		flex-wrap: wrap;
		li{
			float: none;
			width: 100%;
			a{
				color: #FFF !important;
				padding: 0.8em 3em 0.8em 30px!important;
				line-height: 18px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.5);
				display: block !important;
				margin-right: 0;
				width: 100%;
			}
			label {
				width: 3em;
				right: 0em;
				top: 0;

				color: rgba(255, 255, 255, 0.8);
    		}
		}
	}
	#header #portal-globalnav .formations-inter-entreprises{ order: 1; }
	#header #portal-globalnav .intra{order: 2;}
	#header #portal-globalnav .formations-certifiantes{order: 3;}
	#header #portal-globalnav .formations-conseil{order: 4;}

	#homepage .section_bkg.yellow.lilo #lilo p{
		width: 100%;
	}
	#homepage .section_bkg.yellow.lilo #lilo img{
		top: 80px;
		left: 59px;
	}
}


@media (max-width: 540px) {
	#portal-tophead a.espace-pro-link {
		padding: 0;
		text-align: center;
		img{
			margin: 1px 0 0 0;
			float: none;
		}
		span{
			display: none;
		}
	}
	#search-bar {
		order: 3;
	}
}
@media (max-width: 380px) {
	#header #portal-header{
		margin-left: 65px !important;
	}
	#header{
		padding: 0 20px 50px 20px !important;
	}

	#header #portal-globalnav-wrapper .plone-navbar-toggle{
		margin-left: 20px;
	}
}


/*************
	GENERAL
*************/

@media (max-width: 1280px) {

	#header, #footer{
		padding-left: 30px;
		padding-right: 30px;
	}
	#content-wrapper{
		padding-left: 30px;
		padding-right: 30px;
	}

	.template-home #content-wrapper{
		padding-left: 00px;
		padding-right: 00px;
	}

	.template-home #content-outer  #footer{
		padding: 40px 30px 20px 30px;
			
	}

}

@media (max-width: 768px) {
	#content .list-item{
		padding-left: 0;
	}
}

@media (max-width: 380px) {
	dl.item-course dt span.code{
		font-size: 11px;
	}
}

/*************
	HOME
*************/


@media (max-width: 1050px) {
	#homepage #portlets{
		article.vae{
			width: 60%;
			.illu{
				background-size: auto;
				background-position: right center;
			}
		}
		article.cpf{
			width: 40%;
			padding: 30px 40px;
		}

	}
	#homepage #formation_inter article p{
		width: 100%;
	}
	#homepage #conseil article {
	    width: calc(90% - 300px);
	    margin-top: 0;
	}
	#homepage .section_bkg.red #chiffres ul li{
		height: 170px;
	}
	#homepage .section_bkg.red #chiffres ul li img{
		bottom: 120px;
	}
	#homepage .section_bkg.red #chiffres ul li img.no_bottom {
	    bottom: 119px;
	}
	#homepage .section_bkg.red #chiffres ul li p.font_rob.number {
	    font-size: 35px;
	}
	#homepage .section_bkg.red #chiffres ul li p.font_rob {
		font-size: 15px;
		line-height: 17px;
	}
	.leadImage img{
		width:100%;
		height:100%;
	}
}

@media (max-width: 960px) {

	#homepage #portal-searchbox form{
		width: 80%;
	}
	#homepage #formation_inter article{
		padding-right: 0;
		h1,p{
			padding-right: 45%;
		}
		img{
			right:80px;
			top: 26%; /* poussé de la moitié de hauteur du référent */
			  -ms-transform: translateY(-50%); /* IE 9 */
			  -webkit-transform: translateY(-50%); /* Chrome, Safari, Opera */
			  transform: translateY(-50%); /* tiré de la moitié de sa propre hauteur */
		}
		.form_inter_list{
			width: 100%;
		}
	}



	#homepage #portlets article.vae .text{
		width: 45%;
	}
	#homepage #portlets article.cpf img{
		max-width: 45%;
	}
	#homepage #conseil article {
    	width: 64%;
	}
	#homepage #conseil .conseil_logos {
	    float: right;
	    width: 30%;
	}

	#homepage #formation_intra article{
		max-width: 100%;
		// padding-right: 180px;
		box-sizing: border-box;
		.chat_conseil{
			right: 0;
		}
	}

	#homepage #parcours{
		article{
			padding-left:237px;
			
		}
		img{
			right: auto;
			left: 30px;
			width: 275px;
		}
	}
}

@media (max-width: 768px) {
	#homepage .section_bkg.yellow.lilo #lilo,
	.template-home #content-outer #formation_inter,
	.template-home #content-outer #formation_intra, .template-home #content-outer #parcours,
	.template-home #content-outer #conseil,
	#homepage #portal-searchbox{
		padding: 30px !important;
	}

	#homepage .section_bkg.yellow.lilo #lilo {
		img{
			left: 30px;
		}
	}
	#homepage #formation_inter article img{
		right: 30px;
	}
	#homepage #parcours img{
		left: 30px;
		width: 205px;
		top: 50px;
	}
	#homepage #conseil {
		article{
			width: 70%;
		}
		.conseil_logos{
			width: 20%;
			margin-top: 0;
			img{
				max-width: 100%;
			}
		}
	}
	#homepage #portlets article.vae .text {
	    width: 68%;
	    left: 10px;
	}
	#homepage .section_bkg.red #chiffres{
		padding: 20px 10px 0 10px;
	}
	#homepage .section_bkg.red #chiffres ul li{
		height: 150px;
		width: 33%;
		margin-bottom: 20px;
	}
	#homepage .section_bkg.red #chiffres ul li img{
		bottom: 100px;
	}
	#homepage .section_bkg.red #chiffres ul li img.no_bottom {
	    bottom: 97px;
	}
	#homepage .section_bkg.red #chiffres ul li p.font_rob.number {
	    font-size: 35px;
	    margin-top: 60px;
	    margin-bottom: 5px;
	}
	#homepage .section_bkg.red #chiffres ul li p.font_rob {
    font-size: 15px;
    line-height: 17px;
	}

}

@media (max-width: 620px) {
	#homepage #portal-searchbox form{
		width: 100%;
	}
	#homepage #formation_inter article h1, #homepage #formation_inter article p, #homepage #formation_intra article {
	    padding-right: 0%;
	}
	#homepage #formation_inter article img{
		display: none;
	}
	#homepage #formation_inter article ul {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    max-width: 100%;
	}

	#homepage #formation_intra article .chat_conseil{
		position: static;
		display: inline-block;
		float: right;
		margin-top: 20px;
	}
	#homepage #parcours article{
		padding-left: 0;
		img{
			display: none;
		}
	}
	#homepage #conseil{
		display:flex;
    	flex-flow:column;
    	article{
    		width: 100%;
    		order:-1;
    	}
    	.conseil_logos{
    		width: 100%;
    		text-align: center;
    		img{
    			width: 25%;
    			height: auto;
    		}
    		img+img{
    			margin-left: 10%;
    		}
    		
    	}
	}
	#homepage #conseil .conseil_logos {
	    width: 50%;
	    position: relative;
	    margin-top: -50px;
	    img{
			width: 80%;
	    }
	}
	#homepage #portlets article.vae, #homepage #portlets article.cpf{
		width: 100%;
	}
	#homepage #portlets article.vae .text{
		width: 58%;
		left: 0;
	}
	#homepage #portlets article.cpf::after {
	    content: none;
	}
}

@media (max-width: 460px) {
	#homepage #conseil .conseil_logos {
		text-align: left;
		margin-top: -70px;
		img {
    	width: 80%;
    	padding-left: 0;
    	}
	}
	#homepage section button {
	    padding: 15px 35px;
	}
	#homepage #portlets {
		overflow: visible;
		article.vae{
			padding: 80px 30px 30px 30px;
			.illu{
				top: -40px;
				left: 0;
				height: 140px;
				width: 100%;
				background-size: contain;
				background-position: left top;
			}
			.text{
				width: 100%;
				position: static;
			}
		}

	}
	#homepage .section_bkg.red #chiffres ul li{
		height: 150px;
		width: 50%;
		margin-bottom: 20px;
	}
	#homepage .section_bkg.yellow.lilo #lilo article{
		padding-left: 0;
		img{
			position: static;
			float: left;
		}
		h1{
			float: left;
			width: 65%;
		}
		p{
			clear: left;
		}
	}
	#homepage #portlets article.vae {
	    padding: 30px;
	    overflow: hidden;
	    .illu{
			bottom: -8px;
			left: 62%;
			top: auto;
			width: 280px;
			height: 190px;
			background-size: cover;
	    }
	}
	#homepage #portlets article.cpf img {
    	max-width: 35%;
	}
	#formation_intra img{
		width: 55%;
	}

}

@media (max-width: 400px) {
	#portal-searchbox h2 {
	    font-size: 1.6em;
	}
}


/*************
	PORTLETS
*************/

@media (min-width: 768px) {
	#content.content-right, #content.content-left {
     width: calc(100% - 300px);
}

}
@media (max-width: 1240px) {
	#right {
	    width: 230px;
	    float: right;
	    margin-left: 30px;
	    font-size: 90%;
	}

	#content.content-left-right {
	    width: calc(100% - 560px);
	}
}

@media (max-width: 1050px) {
	#content.content-left-right {
	    width: calc(100% - 300px);
	}
	#content.content-left-right+#right {
	    width: 100%;
	    float: none;
	    margin-left: 00px;
	    font-size: 100%;
	    clear: both;
	    .portletWrapper{
	    	width: 270px;
	    	float: left;
	    	margin-right: 30px;
	    	&:last-child{
	    		margin-right: 0;
	    	}
	    }
	}
}

@media (max-width: 768px) {
	#maincontent{
		display: flex;
        flex-wrap: wrap;
	}
	.template-home #maincontent{
		display: block;
	}
	#content.content-left-right, #content.content-left, #content.content-right{
	    width: 100%;
	    order:1;
	}
	#left{
		order: 2;
		width: 100%;
		margin-right: 0;
		.portlet{
	    	width: 270px;
	    	float: left;
	    	margin-right: 30px;
	    	&:last-child{
	    		margin-right: 0;
	    	}
	    }
	    .portletNavigationTree{
	    	width: 100%;
	    }
	}
	#right{
		width: 100%;
	    float: none;
	    margin-left: 00px;
	    font-size: 100%;
	    clear: both;
	    order: 3;
	    .portletWrapper{
	    	width: 270px;
	    	float: left;
	    	margin-right: 30px;
	    	&:last-child{
	    		margin-right: 0;
	    	}
	    }
	}
	
}


// SEARCH NAVBAR CORRECTIF

#header{
	#portal-tophead{
		position: relative;
		width: 100%;
		height: 1px;
		z-index: 140;
		.relative {
		    float: right;
		    position: static;
		    padding-left: 55px;
		    #search-bar.toggled{
		    	width: calc(100% - 700px);
		    }
		}
	}
}

@media (max-width: 1050px) {
	#header{
		#portal-header {
		    padding: 0px 0 !important;
		    a#portal-logo {
			    top: -5px;
			}
		}
		#portal-tophead .relative #search-bar.toggled {
		    width: calc(100% - 580px);
		}
	}
}

@media (max-width: 905px) {
	#header{
		
		#portal-tophead .relative #search-bar.toggled {
		    width: calc(100% - 520px);
		}
	}
}

@media (max-width: 815px) {
	#header{
		
		#portal-tophead .relative #search-bar.toggled {
		    width: calc(100% - 480px);
		}
	}
	#nous-contacter span {
		display: none;
	}
	#nous-contacter, #espace-pro {
		padding: 0 10px;
	}
}

@media (max-width: 560px) {
	a#portal-logo::after{ display: none; }
}

@media (max-width: 500px) {
	#header #portal-tophead .relative #search-bar.toggled {
    	width: calc(100% - 120px);
	}
	#header #portal-header a#portal-logo {
    	top: -0px;
	}
}

