.section-formations-inter-entreprises #content {
  header {
    margin-bottom: 10px;
  }
  .nav_ancre {
    margin: 20px 0 20px 0;
    list-style-type: none;
    padding: 0;

    li {
      display: inline-block;
      width: auto;
      margin-right: 15px;
      margin-bottom: 5px;
      a {
        display: inline-block;
        background-color: #475d68;
        font-family: "Playfair Display", serif;
        color: #fff !important;
        font-size: 14px;
        padding: 5px 10px;
        &:hover {
          background-color: @red;
        }
      }
    }
  }
  legend {
    font-size: inherit;
    color: @blueDark;
    border: 0;
    margin-top: 50px !important;
    h2 {
      margin-top: 35px;
      position: relative;
      &:before {
        display: block;
        content: "";
        position: absolute;
        height: 2px;
        width: 105px;
        background-color: #d7316c;
        top: -17px;
        // left: -80px;
      }
    }
  }
}
#viewlet-below-content-title {
  clear: both;
  #viewlet-social-like > div {
    display: inline-block;
    height: 26px !important;
    height: 26px !important;
    margin: 0 15px 0 0;
    line-height: 26px;
    span {
      vertical-align: top !important;
    }
  }
  .fb_iframe_widget {
    top: -2px;
  }
}

@media (max-width: 660px) {
  .section-formations-inter-entreprises #content .nav_ancre li a {
    font-size: 13px;
    padding: 5px 5px;
  }
}

@media (max-width: 560px) {
  .section-formations-inter-entreprises #content .nav_ancre li a {
    min-width: 85px;
    text-align: center;
  }
  .section-formations-inter-entreprises #content header {
    display: flex;
    flex-wrap: wrap;
    h1.documentFirstHeading {
      width: 100%;
      clear: left;
      order: 1;
    }
    .code {
      float: left;
      text-align: left;
      width: 100%;
      font-size: 60%;
      order: 2;
      font-weight: bold;
      padding: 20px 0;
    }
  }
}

// PICTOS TITRES

.section-formations-inter-entreprises #content {
  .tab {
  }
  #tab1 {
    background: url(/++theme++afpols.theme/theme/static/theme/img/picto_qui.svg)
      no-repeat left top;
    padding-left: 80px;
    background-size: 65px auto !important;
  }
  #tab2 {
    background: url(/++theme++afpols.theme/theme/static/theme/img/picto_objectifs.svg)
      no-repeat left top;
    padding-left: 80px;
    background-size: 50px auto !important;
  }
  #tab3 {
    background: url(/++theme++afpols.theme/theme/static/theme/img/picto_contenu.svg)
      no-repeat left top;
    padding-left: 80px;
    background-size: 50px auto !important;
  }
  #tab4 {
    background: url(/++theme++afpols.theme/theme/static/theme/img/picto_pedago.svg)
      no-repeat left top;
    padding-left: 80px;
    background-size: 50px auto !important;
  }
  #prerequis {
    background: url(/++theme++afpols.theme/theme/static/theme/img/picto_prerequis.svg)
      no-repeat left top;
    padding-left: 80px;
    background-size: 40px auto !important;
  }
  #tab5 {
    background: url(/++theme++afpols.theme/theme/static/theme/img/picto_animation.svg)
      no-repeat left top;
    padding-left: 80px;
    background-size: 45px auto !important;
  }
  #tab6 {
    background: url(/++theme++afpols.theme/theme/static/theme/img/picto_date.svg)
      no-repeat left top;
    padding-left: 80px;
    background-size: 45px auto !important;
    table {
      margin-bottom: 20px;
      th {
        font-weight: bold;
        border-bottom: 1px solid #ccc;
      }
      td {
        padding-left: 0;
        padding-right: 30px;
        border-bottom: 1px solid #ccc;
      }
    }
  }
}

.inscript-course {
  background-color: #d6006a;
  background-image: none;
}

// year tabs on course page
.course-tabs {
  display: flex;
  margin-left: -40px;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    width: 120px;
    height: 40px;
    border: 1px solid #ff5463;
    border-radius: 20px;
    background-color: white;
    position: relative;
    text-align: center;

    &.active {
      background-color: #ff5463;
      a {
        color: white;
      }
    }
    a {
      color: lightgrey;
      font-size: 1.6em;
      text-decoration: none;
      width: 100%;
      height: 100%;
      vertical-align: center;
      border: 0 !important;
      position: absolute;
      top: 80%;
      transform: translateY(-50%);
    }
  }
}

.details-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6em;
  margin: 1em 0 0.6em 0;
  background-color: #f8f8f8;
  border-radius: 0.3rem;
  span {
    padding: 0.3rem;
  }
  .number {
    background-color: #ff5463;
    color: white;
    padding: 0.3rem 0.5rem;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }
  .text {
    padding-left: 1rem;
  }
}

.days {
	font-size: 2em;
	margin: 1em 0;
}

.hidden {
  display: none;
}

.portaltype-course {
  .table {
    tr {
      vertical-align: top;
    }
    p {
      padding: 0;
    }
  }
}