#viewlet-social-like {
  #sc-buttons {
    border-radius: 5px;
    padding: 14px 7px;
    background: white;
    width: 660px;
    overflow: hidden;
    margin: 150px auto 0;
    box-shadow: 0 2px 3px rgba(71, 71, 71, 0.31);
  }

  .sc-button {
    background: #dce0e0;
    position: relative;
    display: block;
    float: left;
    height: 40px;
    margin: 0 7px;
    overflow: hidden;
    width: 150px;
    border-radius: 3px;
    -o-border-radius: 3px;
    -ms-border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
  }

  .icon {
    display: block;
    float: left;
    position: relative;
    z-index: 3;
    height: 100%;
    vertical-align: top;
    width: 38px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-radius: 3px 0px 0px 3px;
    border-radius: 3px 0px 0px 3px;
    text-align: center;
  }

  .icon i {
    color: #fff;
    line-height: 42px;
  }

  .slide {
    z-index: 2;
    display: block;
    margin: 0;
    height: 100%;
    left: 38px;
    position: absolute;
    width: 112px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-topright: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 0px;
    -webkit-border-radius: 0px 3px 3px 0px;
    border-radius: 0px 3px 3px 0px;
  }

  .slide p {
    font-family: Open Sans;
    font-weight: 400;
    border-left: 1px solid #fff;
    border-left: 1px solid rgba(255, 255, 255, 0.35);
    color: #fff;
    font-size: 16px;
    left: 0;
    margin: 0;
    position: absolute;
    text-align: center;
    top: 10px;
    width: 100%;
  }

  .sc-button .slide {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .facebook iframe {
    display: block;
    position: absolute;
    right: 16px;
    top: 10px;
    z-index: 1;
  }

  .twitter iframe {
    width: 90px !important;
    right: 5px;
    top: 10px;
    z-index: 1;
    display: block;
    position: absolute;
  }

  .google #___plusone_0 {
    width: 70px !important;
    top: 10px;
    right: 15px;
    position: absolute;
    display: block;
    z-index: 1;
  }

  .linkedin .IN-widget {
    top: 10px;
    right: 22px;
    position: absolute;
    display: block;
    z-index: 1;
  }

  .facebook:hover .slide {
    left: 150px;
  }

  .twitter:hover .slide {
    top: -40px;
  }

  .google:hover .slide {
    bottom: -40px;
  }

  .linkedin:hover .slide {
    left: -150px;
  }

  .facebook .icon,
  .facebook .slide {
    background: #305c99;
  }

  .twitter .icon,
  .twitter .slide {
    background: #00cdff;
  }

  .google .icon,
  .google .slide {
    background: #d24228;
  }

  .linkedin .icon,
  .linkedin .slide {
    background: #007bb6;
  }
  .twitter-share-button {
    margin-left: 60px;
    margin-top: 10px;
  }
}
