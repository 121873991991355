#header #portal-globalnav-wrapper {
  z-index: 18;
  position: relative;
  float: right;
  box-sizing: border-box;
  width: calc(100% - 190px);
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 85px;
  margin-left: 00px;
  text-align: left;
  img {
    display: none;
  }
  input[type="checkbox"] {
    // display: none;
  }
  li {
    float: left;
    position: relative;
    display: block;
    a {
      box-sizing: border-box;
      position: relative;
      display: block;
      padding: 10px 10px 10px 0 !important;
      margin-right: 35px;
      font-size: 14px;
      hyphens: auto;
      word-break: break-word;
      text-transform: uppercase;
      color: @blueDark;
      line-height: 16px;
    }
  }
  li.plain:hover > a {
    color: #d7316c !important;
  }

  li.selected:hover > a {
    color: #d7316c !important;
  }

  li.selected > a {
    color: #d7316c !important;
  }
}

#header #portal-globalnav-wrapper li {
  display: none;
}
#header #portal-globalnav-wrapper {
  .qui-sommes-nous,
  .seminaires,
  .informations-pratiques,
  .actus,
  .afpols-conseil,
  .formations-certifiantes,
  .intra,
  .formations-inter-entreprises,
  .innovation {
    display: block;
  }
}

// SUBMENU

#header #portal-globalnav > li.has_subtree a {
  display: inline-block;
}

#portal-globalnav li .opener ~ ul {
  transition: ease-out 0.35s;
}
#portal-globalnav li ul {
  position: absolute;
  padding-left: 0;
  max-width: 18em;
  width: 18em;
  z-index: 100;
  padding: 0 !important;
  margin-left: 0;
  opacity: 0;
  visibility: hidden;
  transition: ease-out 0.75s;
}

#portal-globalnav .intra,
#portal-globalnav .formations-certifiantes {
  ul {
    right: 25px;
  }
}
#portal-globalnav .afpols-conseil {
  ul {
    right: 10px;
  }
}

#header #portal-globalnav li ul li {
  float: none;
  width: 100%;
  display: block;
  a {
    display: block;
    width: 100%;
  }

  ul {
    li a {
      padding-left: 1em !important;
      background-color: #e14a57;
      &:hover {
        background-color: #d14551;
      }
    }
  }
}

#portal-globalnav .has_subtree .has_subtree ul {
  position: absolute;
  z-index: -1;
  right: 36em;
  top: 0px;
}
#portal-globalnav .has_subtree:hover > ul,
#portal-globalnav .has_subtree .has_subtree:hover > ul,
#portal-globalnav .has_subtree .has_subtree .has_subtree:hover > ul {
  opacity: 1;
  visibility: visible;
  transition: ease-in 0.35s;
  // max-height: 100vh;
  height: auto;
  li {
    display: block;
    float: none;
  }
}

#portal-globalnav li .opener ~ ul {
  transition: ease-out 0.35s;
}
#portal-globalnav li ul {
  position: absolute;
  max-width: 18em;
  width: 18em;
  z-index: 100;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: ease-out 0.75s;
}
#portal-globalnav li ul {
  visibility: hidden;
  opacity: 0;
  height: 0;
}
.dropup,
.dropdown {
  position: relative;
}

#header #portal-globalnav li ul li a {
  min-width: 15em;
  display: inline-block;
  text-transform: none;
  background: @red;
  color: #fff !important;
  padding: 0.5em 1.5em 0.5em 0.6em !important;
  line-height: 18px;
  margin-right: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  &:hover {
    background-color: #e14a57;
  }
}
#portal-globalnav li ul li a.state-private {
  color: rgba(255, 255, 255, 0.5) !important;
}

/*MASQUAGE BLANC*/

.menu-discreet {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

/******************
NAV SPECIFIQUE
******************/

@media (min-width: 1050px) {
  #portal-globalnav-wrapper .container {
    width: 100%;
    padding: 0;
    #portal-globalnav-collapse {
      width: 100%;
      padding: 0;
      #portal-globalnav {
        width: 100%;
      }
      #portal-globalnav > li label {
        display: none;
      }
      #portal-globalnav > li ul label {
        display: block;
      }
      .afpols-conseil a {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }
}

@media (min-width: 1050px) {
  #header #portal-globalnav {
    .index_html {
      display: none;
    }
    .formations-inter-entreprises,
    .intra,
    .formations-certifiantes,
    .afpols-conseil {
      float: right;
      position: relative;
      top: -9px;
      a {
        color: @red;
      }
    }

    .has_subtree .has_subtree:hover > ul {
      left: 18em;
    }
    .formations-inter-entreprises,
    .intra,
    .formations-certifiantes,
    .afpols-conseil {
      .has_subtree .has_subtree:hover > ul {
        left: -18em;
      }
    }
    .nav-formation {
      strong {
        display: block;
        color: @blueDark;
        font-weight: normal;
      }
    }
  }
}

@media (max-width: 1350px) {
  #header #portal-globalnav-wrapper li a {
    margin-right: 15px;
  }
}

@media (max-width: 1160px) {
  #header #portal-globalnav-wrapper {
    li a {
      margin-right: 15px;
    }
  }
  #portal-globalnav .intra,
  #portal-globalnav .formations-certifiantes {
    ul {
      right: 25px;
    }
  }
  #portal-globalnav .afpols-conseil {
    ul {
      right: 10px;
    }
  }
}

@media (max-width: 1275px) {
  #portal-header {
    clear: none;
    margin-top: 15px;
  }

  #portal-tophead {
    float: right;
    margin-top: 00px;
  }
  a#portal-logo::after {
    left: 180px;
    top: -3px;
  }
  #header #portal-globalnav-wrapper {
    width: 100%;
    margin-top: 20px;
    margin-left: 00px;
    li a {
      margin-right: 30px;
    }
  }
  #portal-globalnav .intra,
  #portal-globalnav .formations-certifiantes {
    ul {
      right: 40px;
    }
  }
  #portal-globalnav .afpols-conseil {
    ul {
      right: 25px;
    }
  }
}

@media (max-width: 1200px) {
  #portal-globalnav li ul {
    max-width: 16.5em;
    width: 16.5em;
  }
  #header #portal-globalnav-wrapper {
    li a {
      margin-right: 15px;
    }
  }
  #header
    #portal-globalnav
    .formations-inter-entreprises
    .has_subtree
    .has_subtree:hover
    > ul,
  #header #portal-globalnav .intra .has_subtree .has_subtree:hover > ul,
  #header
    #portal-globalnav
    .formations-certifiantes
    .has_subtree
    .has_subtree:hover
    > ul,
  #header
    #portal-globalnav
    .formations-conseil
    .has_subtree
    .has_subtree:hover
    > ul {
    left: -16.5em;
  }
  #header #portal-globalnav .has_subtree .has_subtree:hover > ul {
    left: 16.5em;
  }
}

@media (max-width: 1080px) {
  #header #portal-globalnav-wrapper {
    li a {
      margin-right: 10px;
    }
  }
  #portal-globalnav .intra,
  #portal-globalnav .formations-certifiantes {
    ul {
      right: 20px;
    }
  }
  #portal-globalnav .afpols-conseil {
    ul {
      right: 5px;
    }
  }
}

@media (max-width: 1050px) {
  #header #portal-header {
    margin-left: 70px !important;
  }
  a#portal-logo {
    height: 59px;
    width: 120px;
    top: 9px;
  }
  a#portal-logo::after {
    height: 32px;
    left: 135px;
    width: 230px;
    top: 2px;
  }
  #portal-header {
    margin-top: 00px;
  }

  a.espace-pro-link {
    font-size: 13px;
  }
  a.espace-pro-link img {
    width: 25px;
    height: 45px;
    margin-right: 5px;
    position: relative;
    top: 3px;
  }
}

@media (max-width: 905px) {
  a#portal-logo {
    height: 54px;
    width: 122px;
    top: 13px;
  }


  a#portal-logo::after {
    height: 32px;
    left: 115px;
    width: 200px;
    top: 4px;
  }
  a.espace-pro-link {
    font-size: 12px;
  }
  #search-bar.toggled {
  }
}
@media (max-width: 815px) {
  #portal-tophead a.espace-pro-link span {
    display: none;
  }

  #header #portal-tophead a.espace-pro-link {
    img {
      margin-right: 0;
      width: 30px;
    }
  }
  #search-bar {
    right: 50px !important;
  }
  #search-bar.toggled {
  }
}

@media (max-width: 760px) {
  #portal-tophead {
    position: relative;
    z-index: 140;
    height: 1px;
    width: 100%;
  }
  .relative {
    position: static;
  }
  a#portal-logo {
    top: -9px;
  }
  #portal-tophead {
    position: relative;
    z-index: 140;
  }

  #search-bar {
    right: 50px !important;
  }
  #search-bar.toggled {
  }
}
@media (max-width: 500px) {
  #portal-tophead a.espace-pro-link span {
    display: none;
  }

  a#portal-logo {
    height: 50px;
    width: 120px;
    top: 15px;
  }
  a#portal-logo::after {
    height: 22px;
    left: 100px;
    width: 150px;
    top: 0px !important;
  }
}

@media (max-width: 435px) {
  a#portal-logo {
    height: 50px;
    width: 120px;
    top: 15px;
  }
  a#portal-logo::after {
    height: 12px;
    left: 70px;
    width: 110px;
    top: -10px !important;
  }
}

////////////////////
// 3eme niveau reset
////////////////////

#portal-globalnav .seminaires ul li ul {
  max-height: 800px;
  overflow-y: scroll;
}

#content {
  .next_previous {
    width: 100%;
    ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      justify-content: right;
      @media (max-width: 760px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
  
      li {
        height: 50px;
        list-style-type: none;
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        a {
          padding: 15px 25px;
          color: black;
          text-transform: uppercase;
          font-weight: bold;
          background-color: #f9f9f9;
          border: 0 !important;
          border-radius: 0 !important;
          &:hover {
            border: 0 !important;
            border-radius: 0 !important;
          }
        }
      }
    }
  }
}
