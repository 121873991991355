@green: rgb(124, 185, 41);
@black: rgb(0, 0, 0);
@grey: rgb(80, 80, 80);
@blue: rgb(53, 81, 158);

#content-banner {
  display: none;
  width: 100%;
  min-height: 300px;
  position: relative;
  img {
    object-fit: cover;
    width: 100%;
    min-height: 300px;
  }

  .content {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin-left: 10%;
    .title {
      font-size: 80px;
      line-height: 90px;
      font-weight: bold;
      color: @green;
    }
    .text {
      font-size: 28px;
      margin-top: 10px;
      color: #fff;
      font-weight: bold;
      max-width: 400px;
      line-height: 32px;
      font-family: "playfair_displayregular", serif !important;
    }
  }
}

.subsection-re-2020 {
  #portal-breadcrumbs {
    display: none;
  }
  #viewlet-social-like {
    display: none;
  }
  #plone-document-byline {
    display: none;
  }
  #content-banner {
    display: block;
    margin-bottom: 60px;
  }

  h1.documentFirstHeading {
    display: none;
  }

  #content {
    h1 {
      color: @green;
      font-size: 40px;
      line-height: 42px;
      font-weight: bold;
      background: none;
      padding-left: 0;
      font-family: "playfair_displayregular", serif !important;
    }
    h2 {
      color: @blue;
      font-size: 26px;
      text-transform: uppercase;
      line-height: 28px;
      font-family: "Arial black", Arial, Helvetica, sans-serif !important;
      
    }
    h3 {
      color: @black;
      font-size: 19px;
      text-transform: uppercase;
      line-height: 22px;
      font-family: "Arial black", Arial, Helvetica, sans-serif !important;
    }

    h4 {
      color: @blue;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 18px;
      font-family: Arial, Helvetica, sans-serif !important;
    }
    h5 {
      color: @green;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 18px;
      font-family: Arial, Helvetica, sans-serif !important;
    }
    h1, h2, h3, h4, h5 {
      margin: 20px 0 5px 0;
    }

    a {
      color: @blue;
      text-decoration: underline;
    }

    .documentDescription {
      font-size: 18px;
      line-height: 24px;
      color: @black;
      text-align: justify;
      font-family: "playfair_displayregular", serif
    }

    p {
      font-size: 16px;
      line-height: 22px;
      color: @grey;
      text-align: justify;
      font-family: Arial, Helvetica, sans-serif,
    }

    hr {
      background-image: url(/++theme++afpols.theme/theme/static/theme/img/re2020/re2020_separator.png);
      height: 10px;
    }

    .next_previous {
      .next,
      .previous {
        a {
          color: white;
          background-color: @green;
          .label,
          .arrow {
            color: white;
          }
          &:hover {
            background-color: darken(@green, 5%);
            .label,
            .arrow {
              color: white;
            }
          }
        }
      }
    }

    #content-core {
      ol {
        margin-left: 30px;
        padding-left: 0;
        li {
          font-size: 16px;
          line-height: 22px;
          text-align: justify;
        }
      }
      ul:not(.pagination, .select2-choices) {
        margin-left: 0;
        padding-left: 0;
        > li {
          font-size: 16px;
          line-height: 22px;
          text-align: justify;
          list-style-type: none;
          color: @grey;
          &::before {
            content: ">";
            color: @green;
            font-weight: bold;
            margin-left: 0;
            margin-right: 6px;
          }
          > ul {
            margin-left: 40px;
            li {
              list-style-type: disc;
              &::before {
                content: "";
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  #left .portlet {
    background: none;
    .portletHeader {
      padding-bottom: 10px !important;
      a {
        padding: 0;
        border: 0;
        background: none;
        span {
          display: none;
        }
        &:after {
          content: "Sommaire";
          font-weight: bold;
          font-family: "playfair_displayregular", serif !important;
          color: @black;
        }
      }

      
    }
    .state-private {
      opacity: 1;
    }
    .navTree {
      background: none;
    }
    .navTreeItem {
      background: none;
      border-top: 1px solid @grey;
      a {
        color: @green;
        font-size: 25px;
        font-weight: bold;
        padding: 10px 28px 10px 0px;
        &.navTreeCurrentItem {
          color: @green !important;
          opacity: 0.5;
        }
      }
    }

    .navTree.navTreeLevel0 .has-submenu > a:after {
      content: "▶";
      font-size: 16px;
    }
    .navTree.navTreeLevel0 .has-submenu a:after {
      color: @green;
    }
    .navTree.navTreeLevel1 .has-submenu a:after {
      color: @blue;
    }
    .navTree.navTreeLevel0 {
      font-family: "playfair_displayregular", serif !important;
      font-weight: bold;
    }

    .navTree.navTreeLevel1 a {
      color: @blue !important;
      text-transform: uppercase;
      font-size: 18px;
      font-family: Arial, Helvetica, sans-serif !important;
    }

    .navTree.navTreeLevel2 a {
      font-weight: normal;
      color: #000 !important;
      font-size: 16px;
      text-transform: none;
      font-family: Arial, Helvetica, sans-serif !important;
    }
  }
}
