@red: #ff5463;
@ping: #fdf1ff;

.full-width-page,
.template-evaluer_competences_view,
.template-mission_glp_view,
.template-mission_rh_view,
.subsection-perspectives-hlm-un-cycle-de-5-conferences-par-denis-burckel {
  .documentFirstHeading {
    display: none;
  }
  .documentDescription {
    display: none;
  }
  #viewlet-below-content-title {
    display: none;
  }
  #content {
    font-size: 100%;
  }
  #content-outer #header {
    padding-bottom: 0 !important;
  }
  #content-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    max-width: 100%;
    #maincontent {
      padding: 0;
      #portal-breadcrumbs {
        display: none;
      }
    }
  }
}

.full-width-page {
  .fixed-block h2 {
    margin-left: 0;
    color: @red;
  }
  h3 {
    font-weight: 600;
  }
}

.fixed-block {
  max-width: 1200px;
  margin: 40px auto;
  padding: 0px 30px;
  h2 {
    margin-left: 15px;
  }
}

.full-width-block {
  margin: 40px auto;
  padding: 50px 30px;
  &.bg-pink {
    background-color: #fdf1ff;
  }
  &.bg-grey {
    background-color: #f9f9f9;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.vcenter {
  display: flex;
  align-items: center;
}
@media (max-width: 940px) {
  .vcenter {
    display: block;
  }
}

.hcenter {
  display: flex;
  align-items: center;
}

.center {
  justify-content: center;
  align-items: center;
}

.round-blocks {
  h2 {
    margin-bottom: 30px;
  }
  @media (max-width: 1200px) {
    > div {
      flex-direction: column;
      > div {
        max-width: 300px;
        margin: 30px 0;
      }
    }
    img {
      width: 100%;
    }
    .next {
      display: none;
    }
  }
}

.head_block {
  margin-top: 40px;
  overflow: hidden;
  .col-lg-8 {
    padding-left: 30px;
  }
  h1 {
    font-size: 60px;
    color: #324851;
    font-weight: 600;
  }
  h2 {
    color: #707070;
  }
  .hr {
    width: 100px;
    border-top: 10px solid #324851;
  }
  &.with-padding,
  .with-padding {
    padding: 30px 90px 30px 90px;
  }

  @media (min-width: 1200px) {
    .with-padding {
      padding-left: 30%;
    }
  }

  &.pink {
    background-color: #fdf1ff;
  }

  img.right {
    height: 300px;
    max-width: 500px;
    @media (max-width: 940px) {
      display: none;
    }
  }
}
&.bg-pink-gradient {
  background: rgb(251, 175, 207);
  background: linear-gradient(
    90deg,
    rgba(251, 175, 207, 0.6054796918767507) 0%,
    rgba(255, 237, 215, 1) 100%
  );
}

&.bg-green-to-blue-gradient {
  background: rgb(163, 252, 179);
  background: linear-gradient(
    90deg,
    rgba(163, 252, 179, 0.6054796918767507) 0%,
    rgba(174, 150, 212, 1) 100%
  );
}

.red_dot_list {
  list-style: none;

  li {
    margin-bottom: 20px;
  }
  li::before {
    content: "\2022";
    color: @red;
    font-weight: bold;
    display: inline-block;
    width: 0.5em;
    font-size: 3em;
    position: relative;
    top: 11px;
  }
}

.tick_list {
  li::before {
    content: "";
    display: inline-block;
    height: 40px;
    width: 40px;
    background-size: 40px;
    background-image: url(/++theme++afpols.theme/theme/static/theme/img/icons8-checked-100.png);
    position: relative;
    top: 13px;
  }
}

.slash-list {
  li::before {
    content: "///";
    color: @red;
    margin-right: 3px;
  }
}

#responsable_vente {
  ul {
    margin-left: 0px;
  }
}

.red {
  color: @red;
}

.legends {
  legend {
    font-size: inherit;
    padding-left: 80px;
    background-size: 65px auto !important;
    border-bottom: 0;
    h2 {
      margin-top: 35px;
      position: relative;
    }
  }
  .who {
    background: url(/++theme++afpols.theme/theme/static/theme/img/picto_qui.svg)
      no-repeat 0 0;
  }
  .prerequis {
    background: url(/++theme++afpols.theme/theme/static/theme/img/picto_prerequis.svg)
      no-repeat 10px 0;
    background-size: 40px auto !important;
  }
}

.block {
  margin: 20px 0px 20px 0px;
  border: 1px solid #cacaca;
  border-radius: 20px;
  box-shadow: 2px 2px 12px #cacaca;
  padding: 20px;
  height: 100%;

  h3 {
    margin-top: -5px;
    margin-bottom: 5px;
  }

  .subtitle {
    font-weight: 600;
    &::before {
      content: ">";
      color: @red;
    }
  }
  .part {
    padding-top: 5px;
  }

  .info-part {
    h4 {
      background: url(/++theme++afpols.theme/theme/static/theme/img/picto_date.svg)
        no-repeat 0px 0px;
      background-size: 22px;
      padding: 5px 0px 0px 30px;
    }
    .next {
      h4 {
        background: url(/++theme++afpols.theme/theme/static/theme/img/picto_date.svg)
          no-repeat 0px 0px;
      }
    }
    .objectives {
      h4 {
        background: url(/++theme++afpols.theme/theme/static/theme/img/picto_objectifs.svg)
          no-repeat 0px 0px;
      }
    }
    ul {
      padding-left: 20px;
    }
  }

  .link-part {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    .download {
      padding: 1.2em 0 1.2em 0;
      color: @red;
    }
  }
  .button {
    margin: 0;
    @media (max-width: 1440px) {
      padding: 15px 25px;
    }
  }
}

.mission {
  .section_bkg {
    height: 280px;
    .row {
      height: 100%;

      .header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        h1 {
          font-size: 40px;
          text-transform: uppercase;
          color: #714718;
        }
      }
    }
  }

  .glp-bkg {
    background: url(/++theme++afpols.theme/theme/static/theme/img/missions/bandeau_glp.jpg)
      no-repeat 0px 0px;
    background-size: cover;
  }
  .rh-bkg {
    background: url(/++theme++afpols.theme/theme/static/theme/img/missions/bandeauRH.jpg)
      no-repeat 0px 0px;
    background-size: cover;
  }

  .icons {
    background-color: #ffffe5;
    padding: 90px;
    .row {
      max-width: 1000px;
      margin: auto !important;
      .col-md-4 {
        height: 300px;
        text-align: center;
        padding: 30px;
        .img-container {
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin-bottom: 8px;
          img {
            display: block;
          }
        }
        span {
          font-weight: 600;
          font-size: 0.8em;
        }
        p {
          font-size: 0.8em;
          line-height: 1.4em;
        }
      }
    }
  }

  h3 {
    margin-top: 50px;
    font-size: 2em;
    span {
      font-size: 1.5em;
      font-weight: 900;
      margin-right: 10px;
      &.first {
        color: orange;
      }
      &.second {
        color: orangered;
      }
      &.third {
        color: #714718;
      }
    }
  }

  ul {
    margin-left: -40px;
    li {
      list-style-type: none;
    }
    li::before {
      content: "//";
      color: orange;
      margin-right: 4px;
    }
  }

  p.big {
    font-size: 1.2em;
    color: #714718;
  }

  .padding {
    padding: 90px 180px;
    @media (max-width: 600px) {
      padding: 90px 90px;
    }
  }

  .button {
    background-color: orangered;
    margin-top: 15px;
  }

  .person {
    background-color: #fbfbfb;
    padding: 90px;
    .row {
      max-width: 800px;
      margin: auto !important;
      .portrait {
        background: url(/++theme++afpols.theme/theme/static/theme/img/missions/fouillou.jpg)
          no-repeat -6px 0px;
        background-size: cover;
        width: 160px;
        height: 160px;
        border-radius: 80px;
      }
      p {
        margin: 0;
        padding: 0;
        &.small {
          font-size: 0.9em;
        }
        a {
          color: orangered !important;
        }
        .linkedin {
          img {
            width: 30px;
            height: 30px;
            position: relative;
            top: 8px;
          }
        }
      }
    }
  }

  .download {
    margin-top: 50px;
    padding: 70px;
    background-color: #ffffe5;

    .row {
      max-width: 800px;
      margin: auto !important;

      .plaquette {
        position: absolute;
        top: -142px;
        background: url(/++theme++afpols.theme/theme/static/theme/img/missions/maquette_glp.png)
          no-repeat 0px 0px;
        background-size: contain;
        width: 390px;
        height: 417px;
        @media (max-width: 992px) {
          position: initial;
          top: initial;
        }
      }
    }
  }

  .person,
  .download {
    h3 {
      margin-top: 20px;
      text-transform: uppercase;
      font-size: 1.2em;
      font-weight: bolder;
    }
  }
}

#mission_rh {
  .icons {
    padding: 20px 90px;
    background-color: #fff1ff;
    .row {
      .col-md-4 {
        height: 200px;
        padding: 30px 80px;

        div {
          font-weight: 600;
          font-size: 0.8em;
          line-height: 1.4em;
        }
      }
    }
  }
  .download {
    background-color: #fff1ff;
    .row .plaquette {
      background: url(/++theme++afpols.theme/theme/static/theme/img/missions/MAQUETTERH.png)
        no-repeat 0px 0px;
    }
  }

  .portrait {
    background: url(/++theme++afpols.theme/theme/static/theme/img/missions/fringuet-1.png)
      no-repeat 0px 0px;
    background-size: cover;
  }

  .button {
    background-color: rgb(245, 105, 210);
    margin-top: 15px;
  }

  a {
    color: rgb(245, 105, 210) !important;
  }

  .first {
    color: #e66cc1;
  }
  .second {
    color: #c11b97;
  }
  .third {
    color: #630156;
  }
}

.section-actus {
  #viewlet-below-content-title .leadImage {
    display: none;
  }
}


dfn {
  text-decoration: underline dotted;
  cursor: help;
}

table {
  th {
    font-weight: bold;
    text-align: center;
    padding: 3px;
  }
}
