/* --- Header --- */

/* --- Personal tools --- */
#portal-personaltools-wrapper.span3 {
  margin-left: 0px;
}

dl#portal-personaltools {
  dd {
    background-color: @pink !important;
  }
  .actionMenuContent {
    clear: left;
    margin: 0;
    left: 0;
    ul {
      list-style: none;
      margin: 0 5px;
    }
    li a {
      color: @white;
    }
    li a:hover {
      color: #000;
      background-color: @white;
    }
  }
}

dl#portal-personaltools.actionMenu.activated dd {
  position: absolute;
  width: 17%;
  min-width: 17%;
  margin-top: 25px;
}

#contentActionMenus {
  float: none
}

.actionMenuContent ul {
  margin: 0
}

/* --- Fine Personal tools --- */

#top-header {
  font-size: @basesizeem;
  text-transform: uppercase;
  z-index: 10;
  position: relative;
  padding-top: 10px;
}

a#portal-logo {
  background: url("/++theme++afpols.theme/theme/static/theme/img/header_logo.svg") no-repeat;
  background-size: cover;
  height: 79px;
  display: block;
  margin: 0 auto;
  position: relative;
  float: left;
  width: 150px;
  margin-right: 40px;
  float: left;
  position: relative;
  bottom: 15px;
}

a#portal-logo:after{
  display: block;
  position: absolute;
  width: 270px;
  height: 64px;
  content: url("/++theme++afpols.theme/theme/static/theme/img/base_line.svg") ;
  left: 190px;
  top: -40px;
}


.searchButton {
  .sprite-search-gray;
  background: url(/++theme++afpols.theme/theme/static/theme/img/sprite.png) no-repeat !important;
  border: none !important;
  text-indent: -9999em;
  position: relative;
  width: 35px !important;
  height: 35px !important;
  margin-left: 7px;
  background-color: transparent;
}

.searchSection {
  display: none;
}

.livesearchContainer a, .livesearchContainer {
  color: black !important;
}

#LSResult {
  margin-top: 0em;
  z-index: 1
}

#livesearchLegend {
  background-color: @pink;
  padding: 0.3em;
  color: white;
  margin-bottom: 0;
  width: 99%;
  font-size: 18px;
  line-height: 25px;
}

#title-page {
  top: 15px;
  position: relative;
  float: left;
  width: 58%;
  h1 {
    float: left;
    margin: 0;
    font-weight: normal;
    font-size: 3em;
  }
  span {
    width: 51%;
    float: left;
    margin-left: 16px;
    line-height: 17px;
    top: 11px;
    position: relative;
  }
}

#title-page.blockelement {
  top: 0px;
  h1 {
    font-size: 1.8em;
    float: none;
  }
  h1 span {
    width: auto;
    float: none;
    font-size: 1.8em;
    color: @pink;
    padding: 0em;
    margin: 0em;
    display: block;
  }
}



#header {
  font-family: @secondaryfontfamily;
  &:after {
    content: ""; 
    display: table;
    clear: both;
  }
}

#portal-header {
  clear: both;
  padding: 10px 0;
  float: left;
  margin-top: 30px;
  #portal-searchbox{
    
  }
}

#loginform {
  margin-top: 10px;
  margin-bottom: 0px;
  font-style: italic;
  label {
    float: left;
    margin-right: 10px;
    width: auto;
    color: @pink;
    font-weight: normal;
  }
  input {
    width: 9em;
  }
  .formControls input {
    background: url(/++theme++afpols.theme/theme/static/theme/img/sprite.png) no-repeat;
    background-position: 0px -40px;
    border: none;
    text-indent: -9999em;
    position: relative;
    width: 35px !important;
    height: 35px !important;
    margin-left: 0px;
    box-shadow: none;
    webkit-box-shadow: none;
    -moz-box-shadow: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
  }
  a.link {
    font-size: 0.7em;
    color: #656565;
    margin-left: 0;
  }
  .field a {
    display: block;
    font-size: 0.8em;
    color: #666;
  }
  
}

#portal-personaltools-wrapper, #portal-personaltools-wrapper dl, #portal-personaltools-wrapper dl dt{
	&:after{
		content: ""; 
		display: table;
		clear: both;
	}
	.actionMenuHeader{
		float: left;
		.floatleft, .floatright{
			margin-top: 2px;
		}
	}
}

dl#portal-personaltools dt{ margin-top: 5px;}

#loginform{
	margin-top: 0;
	div{margin-top: 6px;}
}

#header #portal-tophead {
  .relative{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  #search-bar {
    float: none;
    right: 0px !important;
    position: relative;
    margin: 0;
    
    .pat-livesearch #search-text {
      width: 250px;
    }
    &:not(.toggled) {
      .pat-livesearch input[name="SearchableText"] {
        width: 0px !important;
      }
    }
  }
}
#nous-contacter {
  text-align: right;
  font-size: 1.4rem;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #F9F9F9;
  padding: 0 20px;
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
    font-size: 15px;
  }
}
#espace-pro {
  background-color: #F9F9F9;
  padding: 0 20px;
  a.espace-pro-link{
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 15px;
    height: 100%;
    gap: 4px;
    img{
      width: 30px;
      height: 42px;
      display: inline-block;
    }
  }
}

.form-inline .field,
.form-inline .formControls {
  float: left;
  clear: none;
  margin-right: 10px;
  margin-bottom: 0px
}

.form-inline .formControls {
  padding: 0;
  margin: 0
}

.form-inline a.back {
  display: block;

}

#header .visualClear{display: none;}

/* --- Fine header --- */