.searchPage{
  position: relative;

  .actionMenu.activated{
    dd{
      display: block;
    }
  }
  .actionMenu{
    display: none;

    dt{
      top: 0;
      right: 0;
      position: absolute;
    }

    dd{
      display: none;
      position: absolute;
      right: 0;
      top: 35px;
      width: 300px;
      background-color:#fcfcfd;
      border: 1px solid #e5e5e5;
      padding: 15px;
      z-index:10;
    }
  }
}
#search-results-bar{
  #results-count{
    float: right;
    padding-top: 15px;
    padding-right: 15px;
  }
}
#searchform{
  .input-group{
    max-width: 600px;
    input.searchPage.btn{
      width: 120px;
    }
  }
}
#search-filter input {
  margin-right: 0.5rem;
}

// PAGE RECHERCHE

.template-search {
  #search-filter{
    fieldset{display: none;}
    fieldset+fieldset{display: block; margin: 20px 0;}
  }
  #search-results-wrapper * {
    box-sizing: border-box;
  }
  ol.searchResults{
    margin-left: 0;
    .result-title{
      font-weight: bold;
    }
    .documentLocation.link-location{
      display: none;
    }
  }
  #search-results-wrapper {
    .autotabs{
      padding: 0;
      margin-top: 20px;
    }
    #searchResultsSort{
      float: none;
      width: 100%;
      text-align: left;
      margin: 0;
      padding: 0 15px;
    }
    .pagination ul{
      margin-left: 0;
      padding-left: 30px;
    }
  }

  #searchform{
    .input-group{
        margin-bottom: 20px;
    }
    .searchPage.form-control{
      height: 53px;
    
    }
    .btn-primary{
        color: #FFF;
        background: @red;
        height: 53px;
        text-shadow: none;
        padding: 5px 35px !important;
        &:hover{
          background: #FF6975;
        }
    }
  }
  h1.documentFirstHeading{
    font-size: 1.9em;
    margin-bottom: 25px !important;
  }

  
}

@media (max-width: 1070px) {
  #search-results-bar #results-count{
    float: none;
    font-weight: bold;
    font-size: 15px;
    margin: 10px 0;
  }

}
@media (max-width: 700px) {
  .template-search #search-results-wrapper #searchResultsSort {
    padding: 0;
    max-height: auto;
      .autotab-heading{
        margin-top: 10px;
      }
  }
.autotabs .autotoc-nav #sorting-options{
    width: calc(100% - 65px);
    display: block;
    float: right;
    *{
      box-sizing: border-box;
    }
    a {
      padding: 12px 5px;
      margin: 0 1% 0 0;
      line-height: 14px;
      white-space: normal;
      hyphens: auto;
      width: 32%;
      height: 60px;
      float: left;
    }
  }
}

.relative{
  float: right;
  position: relative;
  padding-left: 55px;
}

// 
// Header Search
//
#search-bar{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

input::-webkit-input-placeholder { color: #d1d1d1;  font-style: italic !important; font-family: 11px; }
input::-moz-placeholder { color: #d1d1d1;  font-style: italic !important; font-family: 12px; }
input:-ms-input-placeholder { color: #d1d1d1;  font-style: italic !important;  font-family: 11px;}
input::placeholder { color: #d1d1d1;  font-style: italic !important; font-family: 11px; }
}


// FERMEE

#search-bar{
  float: right;
  position: relative;
  width: 0%;
  min-width: 50px;
  height: 50px;
  margin-right: 5px;
  -webkit-transition: width 0.8s ease-in;
  -moz-transition: width 0.8s ease-in;
  -o-transition: width 0.8s ease-in;
  -ms-transition: width 0.8s ease-in;
  transition: width 0.8s ease-in;

  //
  position: absolute;
  top: 0;
  right: 185px;

  .pat-livesearch{
    min-width: 0;
  
  #search-text{
   -webkit-backface-visibility: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
    outline: none;
    background: #FFF;
    border: 1px solid #d1d1d1;
    width: 100%;
    height: 50px;
    margin: 0;
    z-index: 10;
    padding: 20px 65px 20px 10px;
    font-family: inherit;
    font-size: 15px;
    color: #999;
    opacity: 0;
    -webkit-transition: all 0.8s ease-in;
      -moz-transition: all 0.8s ease-in;
      -o-transition: all 0.8s ease-in;
      -ms-transition: all 0.8s ease-in;
      transition: all 0.8s ease-in;
  }
  .toggle-search{
     
      -webkit-transition: none;
      -moz-transition: none;
      -o-transition: none;
      -ms-transition: none;
      transition: none;
      
      background: url("/++theme++afpols.theme/theme/static/theme/img/search_grey.svg") no-repeat center center #f9f9f9;
      width: 50px;
      height: 50px;
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0;
      margin: 0;
      border: 0;
      border-radius: 0;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
      z-index: 15;
      box-shadow: none;
      -webkit-box-shadow: none;

  }
  }
}

// OUVERTE

#search-bar.toggled{
  width: 124%;

  
  .pat-livesearch{
    min-width: 0;
    
  #search-text{
    opacity: 1;
  }
  .toggle-search{
      background: url("/++theme++afpols.theme/theme/static/theme/img/search_white.svg") no-repeat center center #ff5463;
      width: 50px;
      height: 50px;
      -webkit-transition: all 0.8s ease-in;
      -moz-transition: all 0.8s ease-in;
      -o-transition: all 0.8s ease-in;
      -ms-transition: all 0.8s ease-in;
      transition: all 0.8s ease-in;
  }
  }
}

.pat-livesearch .livesearch-results {
    display: none;
    position: absolute;
    background-color: #ffffff;
    z-index: 100;
    border-right: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    border-left: 1px solid #d1d1d1;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -moz-box-shadow: none;
    width: 99.9% !important;
    min-width: 300px;
    li{
       border-bottom: 1px solid #ececec;
       &:last-child{
        border-bottom: 0;
       }
    }
      h4{
        font-weight: bold;
        margin: 10px 0;
        font-size: 1.1em;
      }
      p.description{
        padding: 0;
      }
  }


  #search-text{
    -webkit-box-shadow: none;
    box-shadow: none;
    -moz-box-shadow: none;
  }

  .pat-livesearch .livesearch-results li:hover, .pat-livesearch .livesearch-results li.selected {
    background-color: #f9f9f9;
  }


