/********************
PORTLETS NAVIGATION
********************/
.portletNavigationTree ul{
	padding-left: 0;
	li a{
		position: relative;
		padding: 10px 15px 10px 12px;
		display: block;
		font-size: 1.1em;
		&:hover{
			color: @red !important;
		}
	}

	.thumb-icon {
		display:none;
	}
}
.portletNavigationTree .navTree.navTreeLevel0 .has-submenu > a{
	position: relative;
	&:focus{
		outline: 0;
	}
	&:after{
		content: ">";
		display: block;
		color: #a9b1b5;
		position: absolute;
		right: 5px;
		top: 50%; /* poussé de la moitié de hauteur du référent */
  		-ms-transform: translateY(-50%); /* IE 9 */
  		-webkit-transform: translateY(-50%); /* Chrome, Safari, Opera */
  		transform: translateY(-50%); /* tiré de la moitié de sa propre hauteur */
	}
}
.portletNavigationTree .navTree li{
	padding: 0 !important;
	margin: 0 !important;
	a{
		padding: 10px 15px 10px 12px;
		margin-bottom: 0;
		color: @blueDark;
		line-height: 1.2em;
		&:hover{
			color: @red !important;
		}
	}
	.navTreeLevel1{
		background-color: #ececec;
		li {
			padding: 0 !important;
			margin: 0 !important;
			a{
				padding: 8px 15px 8px 22px;
				margin-bottom: 0;
				line-height: 18px;
				&:hover{
					color: @red!important;
				}
			}
		}
	}
	
	.navTreeLevel2{
		background-color: #e3e3e3;
		li {
			padding: 0 !important;
			margin: 0 !important;
			a{
				padding: 8px 15px 8px 38px;
				margin-bottom: 0;
				line-height: 18px;
				&:hover{
					color: @red!important;
				}
				
			}
			ul.navTreeLevel3 li a{
				padding: 8px 15px 8px 52px;
				font-size: 1em;
				background: #f8f8f8 !important;
				border-bottom: 1px solid #e3e3e3!important;
				&:after{
					display: none !important;
				}
			}
		}
	}
}

#left, #right{
	.portlet{
		background: #f5f5f5; /* Old browsers */
		background: -moz-linear-gradient(top,  #f5f5f5 20%, #ffffff 60%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  #f5f5f5 20%,#ffffff 60%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  #f5f5f5 20%,#ffffff 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
	}
}