.template-home,
.template-programmation_view,
.template-envols {
  #content {
    font-size: 100%;
  }
  #content-outer #header {
    padding-bottom: 0 !important;
  }
  #content-wrapper {
	margin: 0 !important;
	padding: 0 !important;
    width: 100%;
    max-width: 100%;
    #maincontent {
      padding: 0;
      #portal-breadcrumbs {
        display: none;
      }
    }
  }
}

// /////////////
// SLIDER
///////////////

#myCarousel {
  margin-top: 15px;
  .slick-dots {
    position: absolute;
    bottom: 15px;
    left: 50%; /* poussé de la moitié de hauteur du référent */
    -ms-transform: translateX(-50%); /* IE 9 */
    -webkit-transform: translateX(-50%); /* Chrome, Safari, Opera */
    transform: translateX(-50%); /* tiré de la moitié de sa propre hauteur */

    z-index: 5;
    margin: 0;
    margin-left: 0px;
    list-style: none;
    padding: 0;
    li {
      float: left;
      overflow: hidden;
    }
    button {
      width: 12px;
      height: 12px;
      padding: 0;
      margin: 0 0 0 5px;
      text-indent: -999px;
      background-color: #ccc;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 12px;
    }
    .slick-active button {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

#homepage,
#envols,
#programmation {
  section {
    padding: 90px 90px;
    font-family: @fontfamily;
    .playfair {
      font-family: "playfair_displayregular", serif;
      font-weight: 400;
      text-transform: none;
    }
    h2 {
      text-transform: uppercase;
      font-family: "abel_probold", sans-serif !important;
      font-size: 1.55em;
      color: #ff5463;
    }
    .playfair {
      font-size: @basesizeem + 0.325em;
      margin: 15px 0;
      display: inline-block;
      line-height: 1;
    }
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    button {
      background-color: @red;
      padding: 15px 55px;
      border-radius: 60px;
      color: #fff;
      text-transform: uppercase;
      border: 0;
      font-size: 0.75em;
      font-family: "abel_probold", sans-serif !important;
      margin: 40px 0 0 0;
      cursor: pointer;
      &:hover {
        background-color: @redHover;
      }
    }
    p.font_rob {
      font-family: @fontfamily;
      font-size: 18px;
    }
    p {
      font-size: 1.25em;
      span.playfair {
        font-size: 0.8em;
        margin: 0;
        line-height: 1.5em;
      }
    }

    ul h2 a {
      font-size: 0.8em;
      font-weight: 400;
    }
  }
  #myCarousel {
    width: 100%;
    padding: 0;
    img {
      width: 100%;
    }
  }
  #portal-searchbox {
    position: static;
    text-align: center;
    .playfair {
      font-size: 2em;
    }
    form {
      position: relative;
      width: 50%;
      margin: 0 auto;
      #searchGadget {
        box-sizing: border-box;
        border: 1px solid @red;
        background-color: #fff;
        width: 100%;
        height: 48px;
        line-height: 48px;
        margin-top: 20px;
        padding-left: 48px;
        border-radius: 0;
      }
      img {
        position: absolute;
        width: 48px;
        height: 48px;
        top: 00px;
        left: 0;
      }
    }
  }
  //////// SLIDE 100%////////
  .section_bkg.grey,
  .portlets_bkg {
    background: #fafafa;
  }
  .before_bkg {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      background-color: #fdf1ff;
      display: block;
      width: 600px;
      height: 100%;
      top: 0;
      left: -600px;
    }
  }

  ////////
  #formation_inter, #formation_envols {
    position: relative;
    padding: 100px 90px 150px 90px !important;
    article {
      box-sizing: border-box;
      width: 100%;
      padding-right: 40%;
      float: left;
      p {
        width: 80%;
      }
      ul {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
        -webkit-column-width: 46%; /* Chrome, Safari, Opera */
        -moz-column-width: 46%; /* Firefox */
        column-width: 46%;
        -moz-column-gap: 4%;
        -webkit-column-gap: 4%;
        column-gap: 4%;
        padding: 0;
        margin: 35px 0 0 0;
        box-sizing: border-box;
        li {
          list-style-type: none;
          width: 100%;
          margin-bottom: 10px;
          h2 {
            font-family: abel_proregular, sans-serif !important;
            margin: 0;
            a {
              display: inline-block;
              background-color: @blue;
              border-radius: 3px;
              color: #fff !important;
              text-transform: uppercase;
              padding: 8px 5px 8px 15px;
              font-size: 0.55em;
              line-height: 1.1em;
              width: 100%;
              box-sizing: border-box;
              &:hover {
                background-color: @blueHover !important;
              }
            }
          }
        }
      }
    }
    img {
      width: 27%;
      margin-left: 0;
      position: absolute;
      top: 105px;
      right: 80px;
      z-index: 10;
    }
  }

  #formation_intra {
    text-align: center;
    article {
      max-width: 50%;
      position: relative;
      margin: 0 auto;
      h2 {
        color: @basecolor;
      }
    }
  }
  #parcours {
    background: #fafafa;
    position: relative;
    img {
      width: 402px;
      position: absolute;
      top: 80px;
      right: 60%;
    }
    article {
      width: 100%;
      padding-left: 45%;
      float: right;
      box-sizing: border-box;
      h2 {
        color: @blue;
      }
      ul {
        margin-left: 0;
        padding-left: 0;
        margin-top: 15px;
        li {
          list-style-type: none;
          h2 {
            font-family: abel_proregular, sans-serif !important;
            margin: 0;
            a {
              display: inline-block;
              color: @red !important;
              text-transform: uppercase;
              font-weight: normal;
              padding: 6px 0px;
              font-size: 0.65em;
              line-height: 1.3em;
              width: 100%;
              &:hover {
                color: @redHover !important;
              }
            }
          }
        }
      }
      button {
        margin: 20px 0;
      }
    }
  }
  .section_bkg.red {
    background: @red;
    width: 100%;
    // margin-bottom: 90px;
    #chiffres {
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      padding: 40px 10px 20px 10px;
      h2 {
        color: white;
        margin-left: 30px;
      }
      ul {
        padding: 10px 0;
        margin: 0;
        li {
          list-style-type: none;
          text-align: center;
          color: #fff;
          width: 20%;
          position: relative;
          height: 200px;
          display: inline-block;
          vertical-align: top;
          img {
            position: absolute;
            bottom: 150px;
            left: 50%; /* poussé de la moitié de hauteur du référent */
            -ms-transform: translateX(-50%); /* IE 9 */
            -webkit-transform: translateX(-50%); /* Chrome, Safari, Opera */
            transform: translateX(
              -50%
            ); /* tiré de la moitié de sa propre hauteur */
          }
          img.no_bottom {
            bottom: 147px;
          }
          p.font_rob.number {
            font-family: abel_probold;
            font-size: 40px;
            color: #fff492;
            margin-top: 70px;
            margin-bottom: 5px;
          }
          p.font_rob {
            font-weight: 300;
            font-size: 17px;
            text-transform: uppercase;
            line-height: 19px;
          }
        }
      }
    }
  }

  .section_bkg.yellow.lilo {
    background: #fff492;
    width: 100%;
    margin-bottom: 0;
    #lilo {
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      padding: 80px 90px;
      width: 100%;
      background: #fff492;
      position: relative;
      .playfair {
        font-size: 1.8em;
        color: black;
      }
      article {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        padding-left: 38%;
        float: left;
      }
      img {
        width: 34%;
        margin-left: 0;
        position: absolute;
        top: 50px;
        left: 79px;
        z-index: 10;
      }
      h1 {
        font-size: 1.3em;
        color: #000;
      }
      p {
        display: block;
        width: 80%;
      }
    }
  }
  #conseil {
    article {
      width: 55%;
      margin-right: 5%;
      float: right;
      text-align: right;
      margin-top: 35px;
      h2 .playfair {
        color: #3e5667;
        margin: 0;
      }
      button {
        background: transparent;
        border: 1px solid #ff5463;
        color: #ff5463;
        margin-bottom: 0;
        &:hover {
          background: transparent;
        }
      }
    }
    .conseil_logos {
      float: right;
      width: 300px;

      img {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 100%;
      }
      img.first_logo {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 0.5px solid #3e5667;
      }
    }
  }
  #qualiopi {
    #logo-qualiopi {
      flex:1;
      display:inline-block;
      background: url("/++theme++afpols.theme/theme/static/theme/img/qualiopi.png")
        no-repeat center;
      width: 100%;
      min-width: 170px;
      height: 100px;
    }
    .text {
      @media (max-width: 1280px) {
        margin-left: 5px;
      }
      @media (max-width: 992px) {
        padding-left: 15px;
      }
      ul {
        margin-left: 0px;
        margin-bottom: 0px;
        li {
          display: list-item;
          list-style-type: disc;
          margin-left: 20px;
        }
      }
    }
  }
  #portlets {
    padding: 0 0px !important;
    overflow: hidden;
    article.vae {
      box-sizing: border-box;
      width: 65%;
      float: left;
      position: relative;
      background-color: #fdf1ff;
      padding: 80px 0px;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -220px;
        left: 0;
        width: 100%;
        background-color: #fdf1ff;
        height: 220px;
      }
      h2 {
        font-size: 1.25em;
        color: @basecolor;
        line-height: 1.2em;
      }
      span.playfair {
        font-size: 2.063em;
        margin-top: 20px;
      }
      .illu {
        position: absolute;
        display: block;
        background: url(/++theme++afpols.theme/theme/static/theme/img/illu_vae.png)
          no-repeat left center;
        background-size: contain;
        width: 65%;
        height: 100%;
        left: -30px;
        top: 32px;
      }
      .text {
        float: right;
        width: 40%;
        position: relative;
        left: -3%;
      }
      button {
        background: transparent;
        border: 1px solid @red;
        color: @red;
        &:hover {
          background: transparent;
          border: 1px solid @redHover;
          color: @redHover;
        }
      }
    }
    article.cpf {
      width: 35%;
      float: left;
      padding: 50px 20px 50px 80px;
      box-sizing: border-box;
      background-color: #fafafa;
      text-align: center;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -220px;
        left: 0;
        width: 100%;
        background-color: #fafafa;
        height: 220px;
      }
      img {
        max-width: 100%;
        margin-top: 20px;
      }
      h2 {
        color: @basecolor;
        line-height: 1.1em;
        font-size: 1.65em;
      }
      p {
      }
      button {
        border: 0;
        background: transparent;
        text-decoration: underline;
        color: @blue;
        margin: 20px 0;
        &:hover {
          color: @blueHover;
        }
      }
      a {
        display: block;
        // position: absolute;
        right: 0;
        bottom: -10px;
      }
    }
  }
  #cpfchat {
    float: right;
    margin-top: -25px;
    padding: 0;
    position: relative;
    margin-right: 30px;
    img {
      width: 70px;
      height: 60px;
    }
  }

  .scrollToTop {
    text-align: center;
    display: block;
    width: 100%;
    padding: 10px 0 40px 0;
    clear: right;
    img {
      display: inline-block;
      background: @red;
      width: 25px;
      height: 25px;
      padding: 8px;
      border-radius: 50px;
    }
  }
}


// SLIDER PLEINE PAGE

.template-home, .template-envols {
  #content-outer {
    max-width: 100%;

    #header,
    #formation_inter,
    #formation_intra,
    #parcours,
    #conseil,
    #portlets {
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.portaltype-plone-site #plone-contentmenu-display {
  display: none;
}

.template-envols, .template-programmation_view {
  #left {
    display: none;
  }
  #content.content-right, #content.content-left {
    width: 100% !important;
  }
}
#envols {
	.main {
		padding-bottom: 0px;
		.col-lg-4{
			@media (min-width: @plone-screen-lg-min) {
				height: 200px;
			}
		}
		.col-lg-8{
			padding-right:60px;
			@media (max-width: @plone-screen-lg-min) {
				padding-right:0px;
			}
		}
		img {
			display: flex;
			width:100%;
			max-width:450px;
			margin: auto;
			position:relative;
			@media (min-width: @plone-screen-lg-min) {
				top:-75px;
			}
			@media (min-width: @plone-screen-xl-min) {
				top:-100px;
			}
		}
	}
  .envols-trainings-list {
    h2 {
      margin-top:25px;
    }
    ul {
      margin-left:0px;
    }
  }
	.renseignements {
		color: @red;
	}
	.hr {
		width: 100px;
		border-top:10px solid @red;
	}
	.section-button {
		padding: 0px;
	}
	.bbutton {
		display: block;
		text-align:center;
	}
	section h2 {
		color:black;
		text-transform: none;
	}
	.big-circle{
		position:absolute;
		right: -30px;
		top: 1180px;
		height: 180px;
		width: 180px;
		opacity: 0.8;
		border-radius: 50%;
		background-color: @red;
		@media (max-width: @plone-screen-md-min) {
			display:none;
		}
	}
	.with_envols{
		max-width: 70%;
		margin:auto;
		position: relative;

		ul {
			margin-top: 60px;
			list-style: none;
			li {
				font-size: 1.2em;
				min-height: 30px;
				margin-bottom: 20px;
				position:relative;
				.img-container {
					display: inline-block;
					position:absolute;
					left:-100px;
					top:-20px;
					.circle {
						height: 35px;
						width: 35px;
						position: absolute;
						opacity: 0.8;
						left: -10px;
						top: 27px;
						background-color: @red;
						border-radius: 50%;
						display: inline-block;
					}
					img {
						width: 70px;
						height: 70px;
					}
				}
			}
		}

	}
	.violet {
		background-color: #CACCFF;
		padding:30px 0px;
		.row {
			width:50%;
			margin:auto;
			img {
				width: 100%;
			}
			@media (max-width: @plone-screen-md-min) {
				width:100%;
			}
			@media (max-width: @plone-screen-lg-min) {
				width:75%;
			}
		}
	}

	.plus {
		padding-bottom:0px;
    .row {
      display:flex;
      justify-content: center;
      align-items: center;
      .col-lg-6:first-child {
        padding: 3em;
        @media (max-width: @plone-screen-lg-min) {
          padding: 0;
        }
      }
    }
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				margin: 20px;
			}
			li::before {
				content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
				color: @red; /* Change the color */
				font-weight: bold; /* If you want it to be bold */
				display: inline-block; /* Needed to add space between the bullet and the text */
				width: 1em; /* Also needed for space (tweak if needed) */
				margin-left: -1em; /* Also needed for space (tweak if needed) */
			}
		}
		strong{
			color:@red;
		}
		img {
			width: 100%;
			position: relative;
			top: -50px;
		}
	}
	.architecture {
		padding-bottom:0px;
		.row {
			padding-top: 30px;
			div{
				display: flex;
				.content {
					display: flex;
					justify-content: center;
					width: 80%;
					margin-top:20px;
					@media (max-width: @plone-screen-lg-min) {
						width: 100%;
					}
					img {
						width:100%;
						max-width: 300px;
					}
				}
				.next {
					display: inline-block;
					width: 20%;
					align-self: center;
					@media (max-width: @plone-screen-lg-min) {
						display: none;
					}
				}
			}
		}
		// .row {
		// 	div {
		// 		.content {
		// 			display: inline-block;
		// 			width: 80%;
		// 			img {
		// 				display:block;
		// 				margin: auto;
		// 			}
		// 			.time {
		// 				display: block;
		// 				text-align: center;
		// 				color: @red;
		// 				font-weight: bold;
		// 				font-size: 1.6em;
		// 				span {
		// 					font-size: 1.2em;
		// 				}
		// 			}
		// 			p {
		// 				text-align:center;
		// 			}
		// 			.asterisk {
		// 				font-size: 0.8em;
		// 			}
		// 		}
		// 		.next {
		// 			display: inline-block;
		// 			width: 20%;
		// 		}
		// 	} 
		// }

	}
	#formation_envols ul {
		@media (max-width: @plone-screen-md-min) {
			column-count: 1 !important;
    		max-width: 100%;
		}
	}

}

.button {
  background-color: @red;
  padding: 15px 55px;
  border-radius: 60px;
  color: #fff;
  text-transform: uppercase;
  border: 0;
  font-size: 1em;
  font-family: abel_probold,sans-serif!important;
  margin: 40px 0 0 0;
  cursor: pointer;
}

#programmation {
  .pink{
    background-color:#fdf1ff;
    margin-top: 40px;
    padding: 0px 90px 30px 90px;
    .col-lg-8 {
      padding-left:30px;
    }
    h1 {
      font-size: 60px;
      color: #324851;
    }
    h2 {
      color: #707070;
    }
    .hr {
      width: 100px;
      border-top: 10px solid #324851;
    }
    .vcenter {
      display: flex;
      align-items: center;
    }
    @media (max-width: 940px) {
        .vcenter {
            display: block;
        }
    }
  }
  img{
    width:100%
  }
  .trainings{
    max-width: 1600px;
    margin: auto;
    .row {
      display: flex;
      flex-wrap: wrap;
    }
    article {
      display: flex;
      flex-direction: column;
      padding: 30px;
      margin: 0 auto;
      .box {
        border: 1px solid #cacaca;
        border-radius: 20px;
        box-shadow: 2px 2px 12px #cacaca;
        padding: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .header {
          flex: 1;
          h3 {
            font-weight: bold;
            text-align:center;
            margin-top:0;
            
          }
          p {
            flex: 1;
            text-align: center;
            font-size: 1em;
            margin-bottom: 15px;
          }
        }
        .img-container {
          display:flex;
          flex: 2;
          img {
            align-self: center;
          }
        }
        .footer {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          a.download {
            flex: 1;
            margin: 10px 0px;
            font-size: 0.9em;
            color: #7081d7;
          }
          a.discover {
            button {
              padding: 12px 30px;
              font-size: 1.1em;
              margin-top: 0px
            }
          }
        }
      }
      @media (max-width: 940px) {
        display: block;
      }
    }
    
  }
}